export const download_table_as_csv =  function (table_id = "id1", separator = ",", clbk) {
    // Select rows from table_id
    var rows = document.querySelectorAll("table#" + table_id + " tr");
    // Construct csv
    var csv = [];
    //looping through the table
    for (var i = 0; i < rows.length; i++) {
      var row = [],
        cols = rows[i].querySelectorAll("td, th");
      //looping through the tr
      for (var j = 0; j < cols.length; j++) {
        // removing space from the data
        var data = cols[j].innerText
          .replace(/(\r\n|\n|\r)/gm, "")
          .replace(/(\s\s)/gm, " ");
        // removing double qoute from the data
        data = data.replace(/"/g, `""`);
        // Push escaped string
        row.push(`"` + data + `"`);
      }
      csv.push(row.join(separator));
    }
    var csv_string = csv.join("\n");
    // Download it
    var filename =
      "export_" + table_id + "_" + new Date().toLocaleDateString() + ".csv";
    var link = document.createElement("a");
    link.style.display = "none";
    link.setAttribute("target", "_blank");
    link.setAttribute(
      "href",
      "data:text/csv;charset=utf-8," + encodeURIComponent(csv_string)
    );
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    if(clbk) clbk()
  }