<template>
  <div class="row">
    <div class="col-sm-6 col-lg mb-2">
      <azoli-value-box label="Total enrégistrés" :showPercentage="showPercentage" :percentage="totalPercentage" :borderClass="borderClass" :subtitle="subtitle"  :rightSubtitle="rightSubtitle" :value="totalCount" :rightValue="rightTotalCount"/>
    </div>
    <div class="col-sm-6 col-lg mb-2">
      <azoli-value-box label="Total Femmes" :showPercentage="showPercentage" :percentage="sexeFPercentage" :borderClass="borderClass" :subtitle="subtitle" :rightSubtitle="rightSubtitle" :value="sexeFCount" :rightValue="rightSexeFCount"/>
    </div>
    <div class="col-sm-6 col-lg mb-2">
      <azoli-value-box label="Total Hommes" :showPercentage="showPercentage" :percentage="sexeMPercentage" :borderClass="borderClass" :subtitle="subtitle" :rightSubtitle="rightSubtitle" :value="sexeMCount" :rightValue="rightSexeMCount"/>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import AzoliValueBox from './AzoliValueBox.vue'

export default {
  props: {
    label: {
      type: String,
      require: true
    },
    borderClass: {
      type: String
    },
    showPercentage: {
      type: Boolean,
      default: true
    },
    dateDeb: {
      type: String,
    },
    dateFin: {
      type: String,
    },
    subtitle: {
      type: String,
      require: true
    },
    criteria: {
      type: String,
      require: true
    },
    regroupements: {
      type: String,
      require: true
    },
    rightCriteria: {
      type: String
    },
    rightRegroupements: {
      type: String
    },
    rightLabel: {
      type: String
    },
    rightSubtitle: {
      type: String
    },
  },
  components: {
    AzoliValueBox,
  },
  data() {
    return {
      delay: 5000,
      options: {
        useEasing: true,
        useGrouping: true,
        separator: '',
        decimal: '.',
        prefix: '',
        suffix: ''
      },
      stat: {},
      rightStat: {}
    }
  },
  created() {
   this.loadData()
  },
  watch: {
    dateDeb () {
      this.loadData()
    },
    dateFin () {
      this.loadData()
    }
  },
  computed: {
    ...mapGetters({
    }),
    sexeFCount(){
      return this.stat['F'] || 0
    },
    sexeMCount(){
      return this.stat['M'] || 0
    },
    totalCount(){
      return this.stat['TOTAL'] || 0
    },
    sexeFPercentage(){
      return this.computePercentage(this.sexeFCount, this.percentageDivider)
    },
    sexeMPercentage(){
      return this.computePercentage(this.sexeMCount, this.percentageDivider)
    },
    percentageDivider(){
     return (this.totalCount? this.totalCount : 1)
    },
    totalPercentage(){
      return this.computePercentage(this.totalCount, this.percentageDivider)
    },
    isDual(){
      return this.rightCriteria != null && this.rightRegroupements != null
    },
    rightSexeFCount(){
      return this.rightStat['F'] || 0
    },
    rightSexeMCount(){
      return this.rightStat['M'] || 0
    },
    rightTotalCount(){
      return this.rightStat['TOTAL'] || 0
    },
  },
  methods: {
    ...mapActions({
      fetchAzoliStatistics: 'statistic/fetchAzoliStatistics',
    }),
    computePercentage(value, divider){
      return this.divider == 0? 1 : (value/divider)*100
    },
    loadData(){
       this.fetchAzoliStatistics({
          criteria: this.criteria,
          regroupements: this.regroupements,
          dateDeb: this.dateDeb,
          dateFin: this.dateFin
        }).then((data) => {
          this.stat = data
        })

        if(this.isDual){
          this.fetchAzoliStatistics({
          criteria: this.rightCriteria,
          regroupements: this.rightRegroupements,
          dateDeb: this.dateDeb,
          dateFin: this.dateFin
        }).then((data) => {
          this.rightStat = data
        })
      }
    }
  },
}
</script>