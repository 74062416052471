<template>
  <div>
    <div class="card">
      <transition>
        <div class="card mx- border-radius-5 mt-2 hadow-5">
          <div class="row p-fluid p-3 grid">
            <div class="field col-12 col-md-3">
              <!-- <label for="basic">Date de début</label> -->
              <p-calendar
                placeholder="Date de début"
                v-model="dateDeb"
                locale="fr"
                :showIcon="true"
                :manualInput="false"
              />
            </div>
            <div class="field col-12 col-md-3">
              <!-- <label for="basic">Date de fin</label> -->
              <p-calendar
                placeholder="Date de fin"
                class="flex-grow-1"
                v-model="dateFin"
                :showIcon="true"
                :manualInput="false"
              />
            </div>
            <div class="field col-12 col-md-3">
              <button
                type="button"
                @click.prevent="submitQuery"
                class="style_btn btn btn-warning ms-2"
                id="button-query"
              >
                <i class="bi bi-search"></i>
              </button>
            </div>
          </div>
        </div>
      </transition>
      <PDivider />

      <p-progress-bar v-if="loading" style="height: 0.33em" mode="indeterminate" />
      <div class="d-flex justify-content-between mb-3 px-2 border">
        <div>
          <PButton
            v-for="(mode, i) in viewModeOptions"
            :key="i"
            @click.prevent="() => (viewMode = mode.value)"
            :icon="mode.icon"
            v-tooltip="mode.label"
            class="p-button-rounded p-button-success p-button-lg mx-2"
            :class="{ 'p-button-outlined': mode.value != viewMode }"
          />
        </div>
        <div>
          <button type="buton" class="btn btn-primary" @click="exportCSV">Export</button>
        </div>
      </div>
      <template v-if="viewMode == 'DATA'">
        <div v-if="stats.dataCounts && stats.dataCounts != null">
          <div
            data-scrollselectors=".p-datatable-wrapper"
            class="p-datatable-sm p-datatable p-component p-datatable-responsive-scroll p-datatable-grouped-header p-datatable-gridlines"
          >
            <div class="p-datatable-wrapper">
              <table id="statisticTable" role="table" class="p-datatable-table">
                <template v-for="(item, i) in stats.dataCounts">
                  <thead :key="'_st_' + i" role="rowgroup" class="p-datatable-thead mb-1">
                    <template>
                      <tr class="bg-success">
                        <th role="cell" colspan="3">
                          {{ item.libelleGlobal }}
                        </th>
                        <th role="cell" colspan="3" width="50%">
                          {{ item.libelleZoneVulnerable }}
                        </th>
                      </tr>
                      <tr>
                        <th role="cell">
                          <span>F</span>
                        </th>
                        <th role="cell">
                          <span>H</span>
                        </th>
                        <th role="cell">
                          <span>T</span>
                        </th>
                        <th role="cell">
                          <span>F</span>
                        </th>
                        <th role="cell">
                          <span>H</span>
                        </th>
                        <th role="cell">
                          <span>T</span>
                        </th>
                      </tr>
                    </template>
                  </thead>
                  <tbody :key="'_sd_' + i" class="mb-1">
                    <tr>
                      <td>{{ item.sexeFCount }}</td>
                      <td>{{ item.sexeMCount }}</td>
                      <td>{{ item.sexeTotalCount }}</td>
                      <td>
                        {{ item.vulnerableSexeFCount }}
                      </td>
                      <td>
                        {{ item.vulnerableSexeMCount }}
                      </td>
                      <td>
                        {{ item.vulnerableTotalCount }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </table>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Multiselect from "vue-multiselect";

export default {
  props: {
    extraQueryArg: {
      type: String,
      default: "",
    },
  },
  components: {
    Multiselect,
  },
  data() {
    return {
      stats: [],
      lines: [],
      viewMode: "DATA",
      transposed: false,
      periodicite: null,
      loading: false,
      showAdvancedFilter: false,
      columns: [],
      groupedData: false,
      pivots: [],
      activeStatisticTypeIndex: 0,
      activeStatisticType: null,
      dateDeb: null,
      dateFin: null,

      statisticTypes: [
        {
          label: "Statistiques DE",
          icon: "pi pi-id-card",
          code: "STATISTIC_DE",
          value: "STATISTIC_DE",
          cible: "demandeurs d'emploi",
        },
        // {
        //   label: "Statistiques tâches planifiées",
        //   icon: "pi pi-id-card",
        //   code: "STATISTIC_TACHE",
        //   value: "STATISTIC_TACHE",
        //   cible: "tâches planifiées",
        // },
        {
          label: "Statistiques OFFRE",
          icon: "pi pi-globe",
          value: "STATISTIC_OFFRE",
          cible: "offres",
        },
        {
          label: "Statistiques ENTREPRISE",
          icon: "pi pi-building",
          value: "STATISTIC_ENTREPRISE",
          cible: "entreprises",
        },
        {
          label: "Statistiques PRISE_EN_CHARGE",
          icon: "pi pi-link",
          value: "STATISTIC_PEC",
          cible: "prises en charge",
        },
      ],
      viewModeOptions: [
        { label: "Afficher en mode tableau", icon: "pi pi-table", value: "DATA" },
        {
          label: "Afficher en mode histogramme",
          icon: "pi pi-chart-bar",
          value: "GRAPHIC_BAR_CHART",
        },
        // {
        //   label: "Afficher en mode diagramme circulaire",
        //   icon: "pi pi-chart-pie",
        //   value: "GRAPHIC_DOUGNHUT_CHART",
        // },
        // {
        //   label: "Afficher en mode courbe",
        //   icon: "pi pi-chart-line",
        //   value: "GRAPHIC_LINE_CHART",
        // },
      ],
    };
  },
  created() {
    this.activeStatisticType = this.statisticTypes[0];
    this.queryStatistics();
  },
  computed: {},
  watch: {
    activeStatisticType(val) {
      console.log(val);
      this.activeStatisticTypeIndex = this.statisticTypes.indexOf(
        (el) => el.value == val.value
      );
    },
    dateDeb() {
      this.queryStatistics();
    },
    dateFin() {
      this.queryStatistics();
    },
  },
  methods: {
    ...mapActions({
      fetchAzoliGlobalStatistics: "statistic/fetchAzoliGlobalStatistics",
    }),
    submitQuery() {
      this.queryStatistics();
    },
    queryStatistics() {
      const dateDeb =
        this.dateDeb != null
          ? (this.dateDeb || new Date("2021-12-31")).toISOString().substring(0, 10)
          : null;
      const dateFin =
        this.dateFin != null
          ? (this.dateFin || new Date("2080-12-31")).toISOString().substring(0, 10)
          : null;
      this.loading = true;
      this.fetchAzoliGlobalStatistics({
        extraQueryArg: this.extraQueryArg,
        dateDeb,
        dateFin,
      })
        .then((data) => {
          this.loading = false;
          this.stats = data;
        })
        .catch((err) => (this.loading = false));
    },
    download_table_as_csv(table_id = "id1", separator = ",") {
      // Select rows from table_id
      var rows = document.querySelectorAll("table#" + table_id + " tr");
      // Construct csv
      var csv = [];
      //looping through the table
      for (var i = 0; i < rows.length; i++) {
        var row = [],
          cols = rows[i].querySelectorAll("td, th");
        //looping through the tr
        for (var j = 0; j < cols.length; j++) {
          // removing space from the data
          var data = cols[j].innerText
            .replace(/(\r\n|\n|\r)/gm, "")
            .replace(/(\s\s)/gm, " ");
          // removing double qoute from the data
          data = data.replace(/"/g, `""`);
          // Push escaped string
          row.push(`"` + data + `"`);
        }
        csv.push(row.join(separator));
      }
      var csv_string = csv.join("\n");
      // Download it
      var filename =
        "export_" + table_id + "_" + new Date().toLocaleDateString() + ".csv";
      var link = document.createElement("a");
      link.style.display = "none";
      link.setAttribute("target", "_blank");
      link.setAttribute(
        "href",
        "data:text/csv;charset=utf-8," + encodeURIComponent(csv_string)
      );
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      this.$toast.success("Téléchargement terminé!", {
        position: "top-right",
        duration: 8000,
      });
    },
    exportCSV() {
      //   var wb = XlsxUtils.table_to_book(document.getElementById("statisticTable"));
      //   XlsxWriteFile(wb, this.lineName + "_file.xlsx");
      this.download_table_as_csv("statisticTable");
    },
  },
};
</script>
