<template>
  <div class="row">
    <div class="col-md-12">Statistiques microprojets</div>
    <div class="col-md-12">
      <PDataTable :value="data" class="p-datatable-sm" responsiveLayout="scroll">
        <template #header>
          <div class="row">
            <div class="col-md-6">
              <AsyncSearchInput
                v-model="agent"
                :queryUrl="'agents'"
                :optionLabel="'nomPrenom'"
                placeholder="Rechercher un agent"
              />
              <AsyncSearchInput
                v-model="fonction"
                :queryUrl="'fonctions'"
                :optionLabel="'nom'"
                placeholder="Type agent"
              />
              <!-- <div class="text-right">
                <div class="p-input-icon-left">
                  <i class="pi pi-search"></i>
                  <PInputText v-model="search" placeholder="Rec un agent" size="50" />
                </div>
              </div> -->
            </div>
            <div class="col-md-4">
              <div class="row">
                <div class="field col-12 col-md-3">
                  <p-calendar
                    placeholder="Date de début"
                    v-model="dateDeb"
                    locale="fr"
                    :showIcon="true"
                    :manualInput="false"
                  />
                </div>
                <div class="field col-12 col-md-3">
                  <p-calendar
                    placeholder="Date de fin"
                    class="flex-grow-1"
                    v-model="dateFin"
                    :showIcon="true"
                    :manualInput="false"
                  />
                </div>
              </div>
            </div>

            <div class="col">
              <a
                href="#"
                @click.prevent="submitQuery"
                class="style_btn btn btn-primary"
                id="button-query"
                ><i class="bi bi-search"></i
              ></a>
            </div>
          </div>
        </template>
        <PColumn field="agent.nomPrenom" header="Nom et Prénom Agent"></PColumn>
        <PColumn field="agent.fonction.nom" header="Fonction"></PColumn>
        <PColumn field="projetNouveauCount" header="Nouveau"></PColumn>
        <PColumn field="projetEnCoursCount" header="En cours"></PColumn>
        <PColumn field="projetTermineCount" header="Terminé"></PColumn>
        <PColumn field="projetValideCount" header="Validé"></PColumn>
        <PColumn field="projetTotalCount" header="Total"></PColumn>
      </PDataTable>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import AsyncSearchInput from "../../../../components/common/AsyncSearchInput.vue";

export default {
  data() {
    return {
      data: [],
      search: null,
      agent: null,
      fonction: null,
      dateDeb: null,
      dateFin: null,
      search: null,
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    ...mapActions({
      fetchMicroprojetPerAgentStatistics: "statistic/fetchMicroprojetPerAgentStatistics",
    }),
    loadData() {
      let queryParams = `agent=${this.agent != null ? this.agent.id : ""}&fonction=${
        this.fonction != null ? this.fonction.id : ""
      }`;
      this.fetchMicroprojetPerAgentStatistics({
        extraQueryArg: `${queryParams}`,
      }).then((data) => {
        this.data = data;
      });
    },
    submitQuery() {
      this.loadData();
    },
  },
  components: { AsyncSearchInput },
};
</script>
