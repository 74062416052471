export const aggregationOptions = [
    {
        label: "Sexe",
        desc: "Sexe",
        code: "SEXE",
        isPivot: true,
    },
    {
        label: "Age",
        desc: "Age",
        code: "AGE",
        isPivot: true,
    },
    {
      label: "Agent",
      desc: "Agent ULPE/CE/CA/AE",
      code: "PAR_AGENT",
    },
    {
      label: "Antenne",
      desc: "Antenne",
      code: "PAR_ANTENNE",
    },
    {
        label: "Niveau diplôme",
        desc: "Niveau diplôme",
        code: "NIVEAU_DIPLOME",
        isPivot: true,
    },
    {
        label: "Département",
        desc: "Département",
        code: "DEPARTEMENT",
    },
    {
        label: "Commune",
        desc: "Commune",
        code: "COMMUNE",
    },
    
    {
        label: "Trimestre",
        desc: "Trimestre",
        code: "PAR_TRIMESTRE",
        isPivot: true,
    },
    {
        label: "Semestre",
        desc: "Semestre",
        code: "PAR_SEMESTRE",
        isPivot: true,
    },
    {
        label: "Mois",
        desc: "Mois",
        code: "PAR_MOIS",
        isPivot: true,
    },
    {
        label: "Année",
        desc: "Année",
        code: "PAR_ANNEE",
        isPivot: true,
    },
]


export const autresAggregationOptions = [
    {
      label: "Agent",
      desc: "Agent ULPE/CE/CA/AE",
      code: "PAR_AGENT",
    },
    {
      label: "Antenne",
      desc: "Antenne",
      code: "PAR_ANTENNE",
    },
    {
        label: "Trimestre",
        desc: "Trimestre",
        code: "PAR_TRIMESTRE",
        isPivot: true,
    },
    {
        label: "Semestre",
        desc: "Semestre",
        code: "PAR_SEMESTRE",
        isPivot: true,
    },
    {
        label: "Mois",
        desc: "Mois",
        code: "PAR_MOIS",
        isPivot: true,
    },
    {
        label: "Année",
        desc: "Année",
        code: "PAR_ANNEE",
        isPivot: true,
    },
]

export const deIndicateurOpttions = [
    {
        label: "Sexe",
        desc: "Sexe",
        code: "SEXE",
        isPivot: true,
      },
      {
          label: "Age",
          desc: "Age",
          code: "AGE",
          isPivot: true,
      },
]

export const entrepriseIndicateurOpttions = [
  {
    label: "NBRE",
    desc: "Nombres",
    code: "NBRE_GROUPING",
  }

]
export const offreIndicateurOpttions = [
    {
        label: "NBRE",
        desc: "Nombres",
        code: "NBRE_GROUPING",
      }
    
]
export const priseEnChargeIndicateurOpttions = [
    {
        label: "NBRE",
        desc: "Nombres",
        code: "NBRE_GROUPING",
      }
    
]
