<template>
  <div>
    <div class="card">
      <transition>
        <div class="card mx- border-radius-5 mt-2 hadow-5">
          <div class="row p-fluid p-3 grid">
            <div class="field col-12 col-md-3">
              <!-- <label for="basic">Date de début</label> -->
              <p-calendar
                placeholder="Date de début"
                v-model="dateDeb"
                locale="fr"
                :showIcon="true"
                :manualInput="false"
              />
            </div>
            <div class="field col-12 col-md-3">
              <!-- <label for="basic">Date de fin</label> -->
              <p-calendar
                placeholder="Date de fin"
                class="flex-grow-1"
                v-model="dateFin"
                :showIcon="true"
                :manualInput="false"
              />
            </div>
            <div class="field col-12 col-md-3">
              <button
                type="button"
                @click.prevent="submitQuery"
                class="style_btn btn btn-warning ms-2"
                id="button-query"
              >
                <i class="bi bi-search"></i>
              </button>
            </div>
          </div>
        </div>
      </transition>
      <PDivider />

      <Echelon v-model="echelon"></Echelon>

      <p-progress-bar v-if="loading" style="height: 0.33em" mode="indeterminate" />
      <div class="d-flex justify-content-between mb-3 px-2 border">
        <div>
          <PButton
            v-for="(mode, i) in viewModeOptions"
            :key="i"
            @click.prevent="() => (viewMode = mode.value)"
            :icon="mode.icon"
            v-tooltip="mode.label"
            class="p-button-rounded p-button-success p-button-lg mx-2"
            :class="{ 'p-button-outlined': mode.value != viewMode }"
          />
        </div>
        <div>
          <button type="buton" class="btn btn-primary" @click="exportCSV">Export</button>
        </div>
      </div>
      <template v-if="viewMode == 'DATA'">
        <div v-if="stats != null && stats.length != 0">
          <div
            data-scrollselectors=".p-datatable-wrapper"
            class="p-datatable-sm p-datatable p-component p-datatable-responsive-scroll p-datatable-grouped-header p-datatable-gridlines"
          >
            <div class="p-datatable-wrapper">
              <table
                id="statisticTable"
                role="table"
                class="table p-datatable-table stripped"
              >
                <template>
                  <thead role="rowgroup" class="p-datatable-thead mb-1">
                    <template>
                      <tr>
                        <th role="cell">
                          <span>Enquête</span>
                        </th>
                        <th role="cell">
                          <span>Périod</span>
                        </th>
                        <th role="cell">
                          <span
                            >Nombre de jeunes supportés ayant un emploi ou un travail
                            indépendant douze mois après le soutien qu'ils ont reçu /
                            Nombre total de jeunes supportés</span
                          >
                        </th>
                        <th role="cell">
                          <span
                            >Nombre de jeunes filles supportées ayant un emploi ou un
                            travail indépendant douze mois après le soutien qu'ils ont
                            reçu / Nombre total de jeunes filles supportées</span
                          >
                        </th>
                        <th role="cell">
                          <span
                            >Nombre de jeunes hommes supportés ayant un emploi ou un
                            travail indépendant douze mois après le soutien qu'ils ont
                            reçu / Nombre total de jeunes hommes supportés</span
                          >
                        </th>
                        <th role="cell">
                          <span>
                            (taux de sous-emploi chez les bénéficiaires féminins) - (taux
                            de sous-emploi chez les bénéficiaires masculins) - Au début de
                            leur entrée au programme
                          </span>
                        </th>
                        <th role="cell">
                          <span
                            >(taux de sous-emploi chez les bénéficiaires féminins) - (taux
                            de sous-emploi chez les bénéficiaires masculins) - Douze mois
                            après</span
                          >
                        </th>
                        <th role="cell">
                          <span
                            >Pourcentage de jeunes qui, 12 mois après avoir reçu le
                            soutien du système Azoli, déclarent que leurs revenus ont
                            augmenté par rapport à ceux qu'ils avaient avant de bénéficier
                            du système Azoli</span
                          >
                        </th>

                        <th role="cell">
                          <span
                            >Pourcentage des bénéficiaires directs du projet qui étaient
                            en situation de sous-emploi et peu ou pas instruits au début
                            de la formation.
                          </span>
                        </th>
                        <th role="cell">
                          <span
                            >Pourcentage de femmes bénéficiaires directs du projet qui
                            étaient en situation de sous-emploi et peu ou pas instruits au
                            début de la formation.</span
                          >
                        </th>
                        <th role="cell">
                          <span
                            >Pourcentage de jeunes hommes bénéficiaires directs du projet
                            qui étaient en situation de sous-emploi et peu ou pas
                            instruits au début de la formation.</span
                          >
                        </th>

                        <th role="cell">
                          <span
                            >Effectif de jeunes bénéficiaires ayant bénéficiaires
                            interrogés douze mois après avoir reçu le programme</span
                          >
                        </th>
                      </tr>
                    </template>
                  </thead>
                  <tbody v-for="(item, i) in stats" :key="'_sd_' + i" class="mb-1">
                    <tr class="">
                      <td>{{ item.libelle }}</td>
                      <td>
                        {{ item.dateDebut | formatDate }} à
                        {{ item.dateFin | formatDate }}
                      </td>
                      <!-- <td></td> -->
                      <td>
                        {{ item.recruteTotal }}/{{ item.supporteTotal }}
                        <span class="fw-bold"
                          >({{
                            item.supporteTotal != 0
                              ? ((item.recruteTotal * 100) / item.supporteTotal).toFixed(
                                  2
                                )
                              : 0
                          }}%)</span
                        >
                      </td>
                      <td>
                        {{ item.recruteFemmeTotal }}/{{ item.supporteFemmeTotal }}
                        <span class="fw-bold"
                          >({{
                            item.supporteFemmeTotal != 0
                              ? (
                                  (item.recruteFemmeTotal * 100) /
                                  item.supporteFemmeTotal
                                ).toFixed(2)
                              : 0
                          }}%)</span
                        >
                      </td>

                      <td>
                        {{ item.recruteHommeTotal }}/{{ item.supporteHommeTotal }}
                        <span class="fw-bold"
                          >({{
                            item.supporteHommeTotal != 0
                              ? (
                                  (item.recruteHommeTotal * 100) /
                                  item.supporteHommeTotal
                                ).toFixed(2)
                              : 0
                          }}%)</span
                        >
                      </td>

                      <td>
                        {{ item.sousEmploiEntreeFemmeTotal }}/{{
                          item.supporteFemmeTotal
                        }}
                        - {{ item.sousEmploiEntreeHommeTotal }}/{{
                          item.supporteHommeTotal
                        }}
                        <span class="fw-bold"
                          >({{
                            (
                              (item.supporteFemmeTotal != 0
                                ? (
                                    (item.sousEmploiEntreeFemmeTotal * 100) /
                                    item.supporteFemmeTotal
                                  ).toFixed(5)
                                : 0) -
                              (item.supporteHommeTotal != 0
                                ? (
                                    (item.sousEmploiEntreeHommeTotal * 100) /
                                    item.supporteHommeTotal
                                  ).toFixed(5)
                                : 0)
                            ).toFixed(2)
                          }}%)</span
                        >
                      </td>
                      <td>
                        {{ item.sousEmploiSortieFemmeTotal }}/{{
                          item.supporteFemmeTotal
                        }}
                        - {{ item.sousEmploiSortieHommeTotal }}/{{
                          item.supporteHommeTotal
                        }}
                        <span class="fw-bold"
                          >({{
                            (
                              (item.supporteFemmeTotal != 0
                                ? (
                                    (item.sousEmploiSortieFemmeTotal * 100) /
                                    item.supporteFemmeTotal
                                  ).toFixed(5)
                                : 0) -
                              (item.supporteHommeTotal != 0
                                ? (
                                    (item.sousEmploiSortieHommeTotal * 100) /
                                    item.supporteHommeTotal
                                  ).toFixed(5)
                                : 0)
                            ).toFixed(2)
                          }}%)</span
                        >
                      </td>
                      <td>
                        {{ item.revenusAugmentesTotal }}/{{ item.supporteTotal }}
                        <span class="fw-bold"
                          >({{
                            item.supporteTotal != 0
                              ? (
                                  (item.revenusAugmentesTotal * 100) /
                                  item.supporteTotal
                                ).toFixed(2)
                              : 0
                          }}%)</span
                        >
                      </td>

                      <td>
                        {{ item.beneficiaireDirectSupporteTotal }}/{{
                          item.supporteTotal
                        }}
                        <span class="fw-bold"
                          >({{
                            item.supporteTotal != 0
                              ? (
                                  (item.beneficiaireDirectSupporteTotal * 100) /
                                  item.supporteTotal
                                ).toFixed(2)
                              : 0
                          }}%)</span
                        >
                      </td>
                      <td>
                        {{ item.beneficiaireDirectSupporteFemmeTotal }}/{{
                          item.supporteFemmeTotal
                        }}
                        <span class="fw-bold"
                          >({{
                            item.supporteFemmeTotal != 0
                              ? (
                                  (item.beneficiaireDirectSupporteFemmeTotal * 100) /
                                  item.supporteFemmeTotal
                                ).toFixed(2)
                              : 0
                          }}%)</span
                        >
                      </td>

                      <td>
                        {{ item.beneficiaireDirectSupporteHommeTotal }}/{{
                          item.supporteHommeTotal
                        }}
                        <span class="fw-bold"
                          >({{
                            item.supporteHommeTotal != 0
                              ? (
                                  (item.beneficiaireDirectSupporteHommeTotal * 100) /
                                  item.supporteHommeTotal
                                ).toFixed(2)
                              : 0
                          }}%)</span
                        >
                      </td>

                      <td>{{ item.supporteTotal }}</td>
                    </tr>
                  </tbody>
                </template>
              </table>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Multiselect from "vue-multiselect";
import Echelon from "../../../../components/common/echelon/Echelon.vue";
import { download_table_as_csv } from "../../../../utils/download-util.js";

export default {
  props: {
    extraQueryArg: {
      type: String,
      default: "",
    },
  },
  components: {
    Multiselect,
    Echelon,
  },
  data() {
    return {
      stats: [],
      lines: [],
      viewMode: "DATA",
      transposed: false,
      periodicite: null,
      loading: false,
      showAdvancedFilter: false,
      columns: [],
      groupedData: false,
      pivots: [],
      activeStatisticType: null,
      dateDeb: null,
      dateFin: null,

      statisticTypes: [
        {
          label: "Statistiques DE",
          icon: "pi pi-id-card",
          code: "STATISTIC_DE",
          value: "STATISTIC_DE",
          cible: "demandeurs d'emploi",
        },
        // {
        //   label: "Statistiques tâches planifiées",
        //   icon: "pi pi-id-card",
        //   code: "STATISTIC_TACHE",
        //   value: "STATISTIC_TACHE",
        //   cible: "tâches planifiées",
        // },
        {
          label: "Statistiques OFFRE",
          icon: "pi pi-globe",
          value: "STATISTIC_OFFRE",
          cible: "offres",
        },
        {
          label: "Statistiques ENTREPRISE",
          icon: "pi pi-building",
          value: "STATISTIC_ENTREPRISE",
          cible: "entreprises",
        },
        {
          label: "Statistiques PRISE_EN_CHARGE",
          icon: "pi pi-link",
          value: "STATISTIC_PEC",
          cible: "prises en charge",
        },
      ],
      viewModeOptions: [
        { label: "Afficher en mode tableau", icon: "pi pi-table", value: "DATA" },
        {
          label: "Afficher en mode histogramme",
          icon: "pi pi-chart-bar",
          value: "GRAPHIC_BAR_CHART",
        },
        // {
        //   label: "Afficher en mode diagramme circulaire",
        //   icon: "pi pi-chart-pie",
        //   value: "GRAPHIC_DOUGNHUT_CHART",
        // },
        // {
        //   label: "Afficher en mode courbe",
        //   icon: "pi pi-chart-line",
        //   value: "GRAPHIC_LINE_CHART",
        // },
      ],
    };
  },
  created() {
    this.queryStatistics();
  },
  computed: {},
  watch: {
    dateDeb() {
      this.queryStatistics();
    },
    dateFin() {
      this.queryStatistics();
    },
  },
  methods: {
    ...mapActions({
      fetchAzoliEnquetesStatistics: "statistic/fetchAzoliEnquetesStatistics",
    }),
    submitQuery() {
      this.queryStatistics();
    },
    queryStatistics() {
      const dateDeb =
        this.dateDeb != null
          ? (this.dateDeb || new Date("2021-12-31")).toISOString().substring(0, 10)
          : null;
      const dateFin =
        this.dateFin != null
          ? (this.dateFin || new Date("2080-12-31")).toISOString().substring(0, 10)
          : null;
      this.loading = true;
      this.fetchAzoliEnquetesStatistics({
        extraQueryArg: this.extraQueryArg,
        dateDeb,
        dateFin,
      })
        .then((data) => {
          this.loading = false;
          this.stats = data;
        })
        .catch((err) => (this.loading = false));
    },

    exportCSV() {
      //   var wb = XlsxUtils.table_to_book(document.getElementById("statisticTable"));
      //   XlsxWriteFile(wb, this.lineName + "_file.xlsx");
      download_table_as_csv("statisticTable", ",", () => {
        this.$toast.success("Téléchargement terminé!", {
          position: "top-right",
          duration: 8000,
        });
      });
    },
  },
};
</script>
